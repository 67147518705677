// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

// Components
import Container from 'components/global-components/Container'
import PostLayout from 'components/PostLayout'

// Scripts
import { largeUp } from 'scripts/media-query'

// Styles
const PostStyles = styled.section`
  .post-wrapper {
    display: flex;
    flex-direction: column;

    .related-posts-wrapper {
      ${largeUp} {
        position: relative;
        min-width: 350px;
        margin-left:${props => props.theme.space.betweenColumns};
      }
    }

    .related-posts {
      li {
        margin-bottom: 1rem;
        font-size: .75rem;
      }
      
      ${largeUp} {
        position: sticky;
        top: 36px;
        max-height: calc(100vh - 72px);
        overflow-y: auto;

        h4 { margin-top: 0; }
      }
    }

    ${largeUp} { flex-direction: row; }
  }
`

// PropTypes
const propTypes = {
  metaData: PropTypes.objectOf(PropTypes.any),
  pageSlug: PropTypes.string.isRequired,
  postData: PropTypes.shape({
    postHeading: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  siteUrl: PropTypes.string.isRequired,
}

const BlogPostBody = ({
  metaData,
  pageSlug,
  postData,
  siteUrl,
}) => {
  const {
    tags: postTags,
    postHeading,
  } = postData

  return (
    <PostStyles>
      <Container>
        <div className='post-wrapper'>
          <PostLayout
            metaData={metaData}
            pageSlug={pageSlug}
            postData={postData}
            siteUrl={siteUrl}
          />
          <aside className='related-posts-wrapper'>
            <div className='related-posts'>
              <h4>Related Posts</h4>
              {
                postTags.length > 0
                  ? (
                    <StaticQuery
                      query={
                        graphql`
                          query AllTagPosts {
                            allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "blog-post"}}}, sort: {fields: frontmatter___date, order: DESC}) {
                              edges {
                                node {
                                  fields {
                                    slug
                                  }
                                  frontmatter {
                                    tags
                                    postData {
                                      postHeading
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `
                      }
                      render={data => {
                        const { allMarkdownRemark: { edges: posts }} = data
                        const relatedPosts = posts.filter(post => {
                          const { tags } = post.node.frontmatter
                          if (tags.some(tag => postTags.includes(tag)))
                            return post
                        })

                        const postCount = relatedPosts.length

                        if (postCount > 1)
                          return (
                            <ul>
                              {
                                relatedPosts.map((relatedPost, index) => {
                                  const {
                                    node: {
                                      fields: { slug },
                                      frontmatter: { postData: { postHeading: title }},
                                    },
                                  } = relatedPost

                                  if (title !== postHeading)
                                    return (
                                      <li key={`related-post-${index}`}>
                                        <Link to={slug}>{title}</Link>
                                      </li>
                                    )
                                })
                              }
                            </ul>
                          )
                        else if (postCount === 1 && relatedPosts[0].node.frontmatter.postData.postHeading !== postHeading) {
                          const title = relatedPosts[0].node.frontmatter.postData.postHeading
                          const { slug } = relatedPosts[0].node.fields

                          return (
                            <ul>
                              <li>
                                <Link to={slug}>{title}</Link>
                              </li>
                            </ul>
                          )
                        }

                        return <p>No current related posts.</p>
                      }}
                    />
                  )
                  : <p>No current related posts.</p>
              }
            </div>
          </aside>
        </div>
      </Container>
    </PostStyles>
  )
}

BlogPostBody.propTypes = propTypes
export default BlogPostBody
