// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

// Scripts
import setImage from 'scripts/setImage'
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

// Styles
const PostLayoutStyles = styled.div`
  h1 {
    margin-top: 1.5rem;
    margin-bottom: 0;
    padding: 0;

    ${largeUp} {
      position: sticky;
      top: -1px;
      margin-top: 0;
      padding: .75rem 0;
      background-color: ${props => props.theme.color.white};
      z-index: 1;
    }
  }

  img {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .post-info {
    display: flex;
    justify-content: space-between;
  }

  .date,
  .tags,
  .mobile-tags {
    margin: 0;
    font-size: .75rem;
  }

  .date { margin-right: ${props => props.theme.space.betweenColumns}; }

  .tags {
    display: none;

    ${mediumUp} { display: block; }
  }

  .mobile-tags {
    display: block;

    ${mediumUp} { display: none; }
  }

  .shared-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    p {
      margin: 0 .5rem 0 0;
      font-size: .75rem;
    }

    .social-icons {
      display: flex;
      justify-content: space-between;
      width: 115px;

      .social-icon {
        display: block;
        color: ${props => props.theme.color.grey};
        font-size: 2rem;
        line-height: 1;
      }
    }

    ${largeUp} { justify-content: flex-end; }
  }
`

// PropTypes
const propTypes = {
  metaData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  pageSlug: PropTypes.string,
  postData: PropTypes.shape({
    date: PropTypes.string.isRequired,
    postContent: PropTypes.node.isRequired,
    postHeading: PropTypes.string.isRequired,
    postImageData: {
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      imageAlt: PropTypes.string.isRequired,
    },
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  siteUrl: PropTypes.string,
}

const PostLayout = ({
  metaData,
  pageSlug,
  postData,
  siteUrl,
}) => {
  const {
    description,
    title,
  } = metaData

  const {
    date,
    postContent,
    postHeading,
    postImageData: {
      image,
      imageAlt,
    },
    tags,
  } = postData

  const postUrl = siteUrl + pageSlug
  const img = setImage(image)

  let tagString
  if (tags?.length > 0)
    tagString = tags.map((tag, index) => (index === 0 ? ` ${tag}` : ` / ${tag}`))

  return (
    <PostLayoutStyles>
      <div className='post-info'>
        <p className='date'>Published {date}</p>
        {tagString && <p className='tags'>Tags: {tagString}</p>}
      </div>
      <img
        alt={imageAlt}
        src={img}
      />
      {siteUrl && (
        <div className='shared-wrapper'>
          <p>Share this post:</p>
          <div className='social-icons'>
            <FacebookShareButton url={postUrl} >
              <span className='social-icon'>
                <FontAwesomeIcon icon={faFacebookSquare} />
              </span>
            </FacebookShareButton>
            <TwitterShareButton
              hashtags={tags}
              title={title}
              url={postUrl}
              via={siteUrl}
            >
              <span className='social-icon'>
                <FontAwesomeIcon icon={faTwitterSquare} />
              </span>
            </TwitterShareButton>
            <LinkedinShareButton
              source={siteUrl}
              summary={description}
              title={title}
              url={postUrl}
            >
              <span className='social-icon'>
                <FontAwesomeIcon icon={faLinkedin} />
              </span>
            </LinkedinShareButton>
          </div>
        </div>
      )}
      <h1>{postHeading}</h1>
      <div className='post-content'>
        <ReactMarkdown>{postContent}</ReactMarkdown>
      </div>
      {tagString && <p className='mobile-tags'>Tags: {tagString}</p>}
    </PostLayoutStyles>
  )
}

PostLayout.propTypes = propTypes
export default PostLayout
