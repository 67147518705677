// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import BlogPostBody from 'components/page-bodies/BlogPostBody'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({ slug: PropTypes.string.isRequired }),
      frontmatter: PropTypes.shape({
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        ogImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        postData: PropTypes.objectOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string.isRequired,
      }),
    }),
    site: PropTypes.shape({ siteMetadata: PropTypes.shape({ siteUrl: PropTypes.string.isRequired }) }),
  }),
}

const BlogPostTemplate = ({ data }) => {
  const {
    markdownRemark: {
      fields: { slug },
      frontmatter: {
        date,
        description,
        ogImage,
        postData,
        tags,
        title,
      },
    },
    site: { siteMetadata: { siteUrl }},
  } = data

  const blogMetaData = {
    description,
    ogImage,
    robots: {
      follow: true,
      index: true,
    },
    title,
  }

  const allPostData = {
    ...postData,
    date,
    tags,
  }

  return (
    <Layout hasNoHero>
      <MetaData metaData={blogMetaData} />
      <BlogPostBody
        metaData={blogMetaData}
        pageSlug={slug}
        postData={allPostData}
        siteUrl={siteUrl}
      />
    </Layout>
  )
}

BlogPostTemplate.propTypes = propTypes
export default BlogPostTemplate
export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        date(formatString: "MM/DD/YYYY")
        ogImage {
          childImageSharp {
            fluid {
              originalImg
              originalName
            }
          }
        }
        tags
        postData {
          featuredpost
          postContent
          postHeading
          postImageData {
            imageAlt
            image {
              childImageSharp {
                fluid {
                  originalImg
                  originalName
                }
              }
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

